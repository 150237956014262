import { persist, createJSONStorage } from 'zustand/middleware';
import { getUserAction } from '@/actions/user/getUser';
import { create } from 'zustand';
import { IAddress, IUser } from '@/index';

interface IUserState {
  data: Partial<IUser> | null;
  setData: (data: Partial<IUser> | null) => void;
  clearUser: () => void;
  isLoading: boolean;
  error: string | null;
  fetchData: () => Promise<void>;
}

interface ICachedUserAddressState {
  data: Partial<IAddress> | null;
  setData: (data: Partial<IAddress> | null) => void;
  clearUserAddress: () => void;
}

const initialCachedUserAddressState: ICachedUserAddressState = {
  data: null,
} as ICachedUserAddressState;

export const useUserStore = create(
  persist<IUserState>(
    (set) => ({
      data: null,
      setData: (data) => set({ data }),
      clearUser: () => sessionStorage.removeItem('user'),
      isLoading: true,
      error: null,
      fetchData: async () => {
        set({ isLoading: true, error: null });

        const user = await getUserAction();

        if (user.error) {
          set({ error: user.error.message as string, isLoading: false });
          return;
        }

        set({ data: user.data, isLoading: false });
      },
    }),
    {
      name: 'user',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useCachedUserAddressStore = create<ICachedUserAddressState>(
  (set) => ({
    ...initialCachedUserAddressState,
    setData: (data) => set({ data }),
    clearUserAddress: () => set(initialCachedUserAddressState),
  })
);
